<template>
    <div>
        hello Protections data
    </div>
</template>
<script>
export default {
}
</script>
 

